<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="rd8hw5qapa"
        d="M11 6v10H3V6h8M9.5 0h-5l-1 1H0v2h14V1h-3.5l-1-1zM13 4H1v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-1305 -332) translate(105 300) translate(1192 24) translate(8 8)"
              />
              <path
                d="M3 3L21 3 21 21 3 21z"
                transform="translate(-1305 -332) translate(105 300) translate(1192 24) translate(8 8)"
              />
              <g
                transform="translate(-1305 -332) translate(105 300) translate(1192 24) translate(8 8) translate(5 3)"
              >
                <mask id="fdmajunchb" fill="#fff">
                  <use xlink:href="#rd8hw5qapa" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#rd8hw5qapa" />
                <g fill="#90A4AE" mask="url(#fdmajunchb)">
                  <path d="M0 0H24V24H0z" transform="translate(-5 -3)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
