import { axiosInstance as axios } from "./interceptor";
// import axios from "axios";
import { getURLFunc } from "./apiUtils";

export interface SiteContact {
  id?: string;
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone_numbers: string[];
  is_primary_contact: boolean;
}

const getUrl = getURLFunc("/study/api/sites");

export function createContact(siteId: string, contact: SiteContact) {
  return axios
    .post<string>(getUrl(`/${siteId}/contacts`), contact)
    .then((r) => r.data);
}

export function searchContacts(siteId: string) {
  return axios
    .post<SiteContact[]>(getUrl(`/${siteId}/contacts/search`))
    .then((r) => r.data);
}

export function editContact(siteId: string, contact: SiteContact) {
  return axios
    .put<string>(getUrl(`/${siteId}/contacts/${contact.id}`), contact)
    .then((r) => r.data);
}

export function deleteContact(siteId: string, contactId: string) {
  return axios
    .delete<string>(getUrl(`/${siteId}/contacts/${contactId}`))
    .then((r) => r.data);
}
