import { computed, defineComponent, reactive, ref } from "vue";

import { createContact, editContact, SiteContact } from "@/api/SiteContactsApi";
import {
  joinSplitPhoneNumber,
  SplitPhoneNumber,
  splitPhoneNumber,
} from "@/utils/phoneUtils";
import {
  AZ_MESSAGE,
  CORRECT_PHONE_CODE_MESSAGE,
  CORRECT_PHONE_NUMBER_MESSAGE,
  EMAIL_CORRECT_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  FIRST_NAME_MESSAGE,
  LAST_NAME_MESSAGE,
} from "@/utils/validationMessages";
import {
  EMAIL_REGEX,
  NAME_REGEX,
  PHONE_CODE_REGEX,
  PHONE_NUMBER_REGEX,
} from "@/utils/validationRegex";
import router from "@/router";

export default defineComponent({
  name: "SiteContactsForm",
  props: {
    editModel: Object as () => SiteContact,
    siteId: { type: String, default: "" },
    index: { type: Number },
  },
  components: {},
  setup(props, { emit }) {
    const isEdit = computed(() => props.editModel);

    console.log(isEdit.value);
    const phoneNumbers = isEdit.value
      ? {
          phone_numbers:
            props.editModel?.phone_numbers.map(splitPhoneNumber) || [],
        }
      : {
          phone_numbers: [
            { country_code: "", phone_number: "" } as SplitPhoneNumber,
          ],
        };

    const form = reactive({
      first_name: "",
      last_name: "",
      title: "",
      email: "",
      is_primary_contact: false,
      ...props.editModel,
      ...phoneNumbers,
    });

    const rules = reactive({
      first_name: [
        {
          required: true,
          message: FIRST_NAME_MESSAGE,
          trigger: "blur",
        },
        {
          pattern: NAME_REGEX,
          message: AZ_MESSAGE,
          trigger: "blur",
        },
      ],
      last_name: [
        {
          required: true,
          message: LAST_NAME_MESSAGE,
          trigger: "blur",
        },
        {
          pattern: NAME_REGEX,
          message: AZ_MESSAGE,
          trigger: "blur",
        },
      ],
      title: [
        {
          pattern: NAME_REGEX,
          message: AZ_MESSAGE,
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: EMAIL_REQUIRED_MESSAGE,
          trigger: "blur",
        },
        {
          pattern: EMAIL_REGEX,
          message: EMAIL_CORRECT_MESSAGE,
          trigger: "blur",
        },
      ],
    });

    const state = reactive({
      isSubmitted: false,
      loading: false,
    });

    const contactForm = ref<any>(null);
    return {
      state,
      form,
      rules,
      isEdit,
      contactForm,
      loading: false,
      isRequiredFilled: computed(
        () => !!(form.last_name && form.first_name && form.email)
      ),
      submitForm() {
        console.log(contactForm);
        // state.loading = true;
        contactForm.value.validate(async (valid: boolean) => {
          try {
            if (valid) {
              const model = {
                id: undefined,
                ...form,
                phone_numbers: form.phone_numbers?.map((n: any) =>
                  joinSplitPhoneNumber(n)
                ),
              };

              if (isEdit.value) {
                await editContact(props.siteId, model as SiteContact);
              } else {
                const id = await createContact(
                  props.siteId,
                  model as SiteContact
                );

                model.id = id;
              }

              // state.loading = false;
              state.isSubmitted = true;
              emit("submitted", { model, index: props.index });
            } else {
              return false;
            }
          } catch (ex) {
            console.log(ex);
          } finally {
            state.loading = false;
          }
        });
      },
      cancel() {
        emit("cancel");
      },
      addPhone() {
        form.phone_numbers.push({ country_code: "", phone_number: "" } as any);
      },
      removePhone(index: number) {
        form.phone_numbers.splice(index, 1);
      },
      country_code_rules: [
        {
          pattern: PHONE_CODE_REGEX,
          message: CORRECT_PHONE_CODE_MESSAGE,
          trigger: "blur",
        },
      ],
      phone_number_rules: [
        {
          pattern: PHONE_NUMBER_REGEX,
          message: CORRECT_PHONE_NUMBER_MESSAGE,
          trigger: "blur",
        },
      ],
    };
  },
});
