<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="xmr03zo0da"
        d="M2.36 1.333c.04.594.14 1.174.3 1.727l-.8.8c-.273-.8-.447-1.647-.507-2.527H2.36m6.573 8.014c.567.16 1.147.26 1.734.3v.993c-.88-.06-1.727-.233-2.534-.5l.8-.793M3 0H.667C.3 0 0 .3 0 .667 0 6.927 5.073 12 11.333 12c.367 0 .667-.3.667-.667V9.007c0-.367-.3-.667-.667-.667-.826 0-1.633-.133-2.38-.38-.066-.027-.14-.033-.206-.033-.174 0-.34.066-.474.193L6.807 9.587C4.92 8.62 3.373 7.08 2.413 5.193L3.88 3.727c.187-.187.24-.447.167-.68C3.8 2.3 3.667 1.5 3.667.667 3.667.3 3.367 0 3 0z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-137 -332) translate(105 104) translate(32 221) translate(0 7)"
              />
              <path
                d="M2 2L14 2 14 14 2 14z"
                transform="translate(-137 -332) translate(105 104) translate(32 221) translate(0 7)"
              />
              <g
                transform="translate(-137 -332) translate(105 104) translate(32 221) translate(0 7) translate(2 2)"
              >
                <mask id="uznf0qnppb" fill="#fff">
                  <use xlink:href="#xmr03zo0da" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#xmr03zo0da" />
                <g fill="#90A4AE" mask="url(#uznf0qnppb)">
                  <path d="M0 0H16V16H0z" transform="translate(-2 -2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
