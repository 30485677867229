<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="w10hhn6vha"
        d="M8 2.667V1.333H5.333v1.334H8zM1.333 4v7.333H12V4H1.333zM12 2.667c.74 0 1.333.593 1.333 1.333v7.333c0 .74-.593 1.334-1.333 1.334H1.333c-.74 0-1.333-.594-1.333-1.334L.007 4c0-.74.586-1.333 1.326-1.333H4V1.333C4 .593 4.593 0 5.333 0H8c.74 0 1.333.593 1.333 1.333v1.334H12z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-137 -264) translate(105 104) translate(32 153) translate(0 7)"
              />
              <path
                d="M2 2.667L14 2.667 14 14.667 2 14.667z"
                transform="translate(-137 -264) translate(105 104) translate(32 153) translate(0 7)"
              />
              <g
                transform="translate(-137 -264) translate(105 104) translate(32 153) translate(0 7) translate(1.333 2)"
              >
                <mask id="7l6ejkh33b" fill="#fff">
                  <use xlink:href="#w10hhn6vha" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#w10hhn6vha" />
                <g fill="#90A4AE" mask="url(#7l6ejkh33b)">
                  <path d="M0 0H16V16H0z" transform="translate(-1.333 -2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
