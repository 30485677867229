<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="15017apz8a"
        d="M5.333 1.333c.734 0 1.334.6 1.334 1.334C6.667 3.4 6.067 4 5.333 4 4.6 4 4 3.4 4 2.667c0-.734.6-1.334 1.333-1.334m0 6.667c1.8 0 3.867.86 4 1.333h-8C1.487 8.853 3.54 8 5.333 8m0-8C3.86 0 2.667 1.193 2.667 2.667c0 1.473 1.193 2.666 2.666 2.666C6.807 5.333 8 4.14 8 2.667 8 1.193 6.807 0 5.333 0zm0 6.667C3.553 6.667 0 7.56 0 9.333v1.334h10.667V9.333c0-1.773-3.554-2.666-5.334-2.666z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-137 -411) translate(105 104) translate(32 300) translate(0 7)"
              />
              <path
                d="M2 2L14 2 14 14 2 14z"
                transform="translate(-137 -411) translate(105 104) translate(32 300) translate(0 7)"
              />
              <g
                transform="translate(-137 -411) translate(105 104) translate(32 300) translate(0 7) translate(2.667 2.667)"
              >
                <mask id="pjxri6lqeb" fill="#fff">
                  <use xlink:href="#15017apz8a" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#15017apz8a" />
                <g fill="#90A4AE" mask="url(#pjxri6lqeb)">
                  <path
                    d="M0 0H16V16H0z"
                    transform="translate(-2.667 -2.667)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
