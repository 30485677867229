<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="vhsks02v9a"
        d="M11.53 6.52l.92.92-9.06 9.06h-.92v-.92l9.06-9.06M15.13.5c-.25 0-.51.1-.7.29L12.6 2.62l3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41L15.84.79c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L.47 14.75v3.75h3.75L15.28 7.44l-3.75-3.75z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H24V24H0z"
                transform="translate(-1304 -136) translate(105 104) translate(1191 24) translate(8 8)"
              />
              <path
                d="M3 3L21 3 21 21 3 21z"
                transform="translate(-1304 -136) translate(105 104) translate(1191 24) translate(8 8)"
              />
              <g
                transform="translate(-1304 -136) translate(105 104) translate(1191 24) translate(8 8) translate(2.53 2.5)"
              >
                <mask id="s2mqetqdub" fill="#fff">
                  <use xlink:href="#vhsks02v9a" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#vhsks02v9a" />
                <g fill="#90A4AE" mask="url(#s2mqetqdub)">
                  <path d="M0 0H24V24H0z" transform="translate(-2.53 -2.5)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
