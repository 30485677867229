import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "grey-form-card blue" }
const _hoisted_3 = { class: "card-parent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteContactsForm = _resolveComponent("SiteContactsForm")
  const _component_SiteContactsCard = _resolveComponent("SiteContactsCard")
  const _component_CustomPopup = _resolveComponent("CustomPopup")

  return (_ctx.isEditMode)
    ? (_openBlock(), _createBlock(_component_SiteContactsForm, {
        key: 0,
        editModel: _ctx.contacts[_ctx.editIndex]?.contact,
        index: _ctx.editIndex,
        siteId: _ctx.siteId,
        onSubmitted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.contactSubmitted($event.model, $event.index))),
        onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.contactCanceled(_ctx.editIndex)))
      }, null, 8, ["editModel", "index", "siteId"]))
    : (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode("a", {
              href: "#",
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.addNewContact()), ["prevent"]))
            }, "+ Add new contact")
          ])
        ]),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.contacts, (contact, index) => {
          return (_openBlock(), _createBlock("div", {
            key: contact.key
          }, [
            _createVNode(_component_SiteContactsCard, {
              contactItem: contact.contact,
              index: index,
              siteId: _ctx.siteId,
              onSetPrimary: ($event: any) => (_ctx.showContactPopup(index, $event)),
              onEdit: ($event: any) => (_ctx.editContact(index)),
              onDelete: ($event: any) => (_ctx.deleteContact(index))
            }, null, 8, ["contactItem", "index", "siteId", "onSetPrimary", "onEdit", "onDelete"])
          ]))
        }), 128)),
        _createVNode(_component_CustomPopup, {
          title: "Replace Primary contact",
          text: 
        `You already have ${_ctx.state.primaryPopup.previousPrimary} specified as a primary contact for this site. Do you want to overwrite primary contact?`
      ,
          onOk: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setPrimaryContact(true, true))),
          onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setPrimaryContact())),
          visible: _ctx.state.primaryPopup.isVisible
        }, null, 8, ["text", "visible"])
      ]))
}