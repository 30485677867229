import { computed, defineComponent, onBeforeMount, ref } from "vue";

import { SiteProfile } from "@/api/interfaces/SitesInterfaces";

import CustomPopup from "@/components/CustomPopup/CustomPopup.vue";
import SiteContactsCard from "./SiteContactsCard/SiteContactsCard.vue";
import SiteContactsForm from "./SiteContactsForm/SiteContactsForm.vue";
import {
  deleteContact,
  editContact,
  searchContacts,
  SiteContact,
} from "@/api/SiteContactsApi";

interface SiteContactContainer {
  key: string;
  contact?: SiteContact;
}

export default defineComponent({
  name: "SiteContacts",
  props: { site: { type: Object as () => SiteProfile } },
  components: { SiteContactsCard, SiteContactsForm, CustomPopup },
  setup(props) {
    const contacts = ref<SiteContactContainer[]>([]);
    const editIndex = ref<number | null>(null);

    const state = ref({
      deletePopup: {
        isVisible: false,
        contactName: "",
        index: -1,
      },
      primaryPopup: {
        isVisible: false,
        previousIndex: -1,
        previousPrimary: "",
        isPrimary: false,
        index: -1,
      },
    });

    const updateContacts = () => {
      searchContacts(props.site?.id as string).then((contactsList) => {
        contacts.value = contactsList.map((c) => ({
          key: c.id || "new",
          contact: c,
        }));
      });
    };

    onBeforeMount(() => {
      updateContacts();
    });

    const siteId = computed(() => props.site?.id || "");

    const setPrimaryContact = (needToSet?: boolean, both?: boolean) => {
      if (needToSet) {
        const index = state.value.primaryPopup.index;
        const isPrimary = state.value.primaryPopup.isPrimary;

        const contact = contacts.value[index].contact;
        if (contact) {
          editContact(siteId.value, {
            ...contact,
            is_primary_contact: isPrimary,
          });
          if (both) {
            const previousIndex = state.value.primaryPopup.previousIndex;
            editContact(siteId.value, {
              ...(contacts.value[previousIndex].contact as SiteContact),
              is_primary_contact: !isPrimary,
            });
          }
        }
      }
      state.value.primaryPopup.isVisible = false;
    };

    return {
      state,
      contacts,
      siteId,
      editIndex,
      isEditMode: computed(() => editIndex.value !== null),
      addNewContact() {
        console.log("add new contact");
        const newIndex = contacts.value.length;
        contacts.value.push({ key: "new", contact: undefined });
        editIndex.value = newIndex;
      },
      editContact(index: number) {
        console.log("edit contact");
        editIndex.value = index;
      },
      contactSubmitted(model: SiteContact, index: number) {
        const parent = contacts.value[index];
        parent.contact = model;
        editIndex.value = null;
      },
      contactCanceled(index: number) {
        if (contacts.value[index].contact) {
          editIndex.value = null;
        } else {
          contacts.value.splice(index, 1);
        }
      },
      showContactPopup(index: number, isPrimary: boolean) {
        state.value.primaryPopup.index = index;
        state.value.primaryPopup.isPrimary = isPrimary;

        const prevPrimary = contacts.value.find(
          (c) => c.contact?.is_primary_contact
        );
        if (prevPrimary) {
          state.value.primaryPopup.previousIndex = contacts.value.indexOf(
            prevPrimary
          );

          if (prevPrimary === contacts.value[index]) {
            setPrimaryContact();
          } else {
            state.value.primaryPopup.isVisible = true;
            state.value.primaryPopup.previousPrimary = prevPrimary.contact
              ?.last_name as string;
          }
        } else {
          setPrimaryContact();
        }
      },
      // showDeletePopup(index: number) {},
      setPrimaryContact,
      async deleteContact() {
        try {
          const index = state.value.deletePopup.index;
          await deleteContact(
            siteId.value,
            contacts.value[index].contact?.id as string
          );

          contacts.value.splice(index, 1);
        } catch (ex) {
          console.log(ex);
        }
      },
    };
  },
});
