import { splitPhoneNumber } from "@/utils/phoneUtils";

export default function usePhoneFormat() {
  const formatPhone = (code: string, phone: string) => {
    let result = "";
    if (code) {
      result = "+" + code;
    }
    if (phone) {
      const n = phone;
      result += ` ${n.substring(0, 3)}-${n.substring(3, 6)}-${n.substring(6)}`;
    }

    return result;
  };

  return {
    formatPhone,
    formatFullPhone(fullNumber: string) {
      const split = splitPhoneNumber(fullNumber);

      return this.formatPhone(
        split.country_code || "",
        split.phone_number || ""
      );
    },
  };
}
