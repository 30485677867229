import { splitPhoneNumber } from "@/utils/phoneUtils";
import { computed, defineComponent } from "vue";
import { SiteContact } from "@/api/SiteContactsApi";
import usePhoneFormat from "@/uses/usePhoneFormatter";

import EmailIcon from "@/assets/icons/cards/Email.vue";
import PhoneIcon from "@/assets/icons/cards/Phone.vue";
import UserIcon from "@/assets/icons/cards/User.vue";
import WorkOutlineIcon from "@/assets/icons/cards/WorkOutline.vue";
import EditIcon from "@/assets/icons/Edit.vue";
import DeleteIcon from "@/assets/icons/Delete.vue";

export default defineComponent({
  name: "SiteContactsCard",
  props: {
    contactItem: Object as () => SiteContact,
    index: Number,
    siteId: String,
  },
  components: {
    DeleteIcon,
    EditIcon,
    UserIcon,
    WorkOutlineIcon,
    EmailIcon,
    PhoneIcon,
  },
  setup(props, { emit }) {
    return {
      contact: computed(() => props.contactItem as SiteContact),
      phoneNumbers: computed(() =>
        props.contactItem?.phone_numbers.map(splitPhoneNumber)
      ),
      ...usePhoneFormat(),
      setPrimary(isPrimary: boolean) {
        emit("setPrimary", isPrimary);
      },
      editContact() {
        emit("edit");
      },
      deleteContact() {
        emit("delete");
      },
    };
  },
});
