<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="h2t1f0s5fa"
        d="M13.333 1.333C13.333.6 12.733 0 12 0H1.333C.6 0 0 .6 0 1.333v8c0 .734.6 1.334 1.333 1.334H12c.733 0 1.333-.6 1.333-1.334v-8zm-1.333 0L6.667 4.667 1.333 1.333H12zm0 8H1.333V2.667L6.667 6 12 2.667v6.666z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-137 -298) translate(105 104) translate(32 187) translate(0 7)"
              />
              <path
                d="M2 2L14 2 14 14 2 14z"
                transform="translate(-137 -298) translate(105 104) translate(32 187) translate(0 7)"
              />
              <g
                transform="translate(-137 -298) translate(105 104) translate(32 187) translate(0 7) translate(1.333 2.667)"
              >
                <mask id="tnlro6oy8b" fill="#fff">
                  <use xlink:href="#h2t1f0s5fa" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#h2t1f0s5fa" />
                <g fill="#90A4AE" mask="url(#tnlro6oy8b)">
                  <path
                    d="M0 0H16V16H0z"
                    transform="translate(-1.333 -2.667)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
